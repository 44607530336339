<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-if="$can('update', 'reward')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1"
            :to="{
              name: 'update-reward',
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view
        v-model="data"
        :fields="fields"
        :init-value="initData"
      />
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NInputView from '@/components/NInputView.vue'
import FormInput from './formInput'

const RewardRepository = Repository.get('reward')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    NInputView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        username: null,
        email: null,
        password: null,
      },
      initData: {},
      loading: false,
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      RewardRepository.show(this.$route.params.id).then(response => {
        if (response?.data?.data) {
          this.initData = {
            ...response.data.data,
          }
        }
      })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [...FormInput]

    return { fields }
  },
}
</script>
